import type { WaitForConfirmationModes } from '@sb/routine-runner';

import {
  type ConditionalArgument,
  type Step,
  type Routine,
  ActionRequiredError,
} from '../types';

/**
 * Validate WaitForConfirmation step conditions in a conditional.
 *
 * @see https://github.com/standardbots/sb/blob/michael/promptuser-validate-conditionals/apps/remote-control/utils/steps/getStepVariableInfo.ts#L164
 */
export const validateConditionalWaitForConfirmationStep = (
  condition: ConditionalArgument.ConditionalBuilderState,
  step: Step.ConvertedConfiguration,
) => {
  if (!condition.variable || condition.variable?.kind !== 'step') return;

  const { variableName, stepPosition } = condition.variable;

  if (step.stepKind !== 'WaitForConfirmation' || step.args == null) {
    throw new Error(
      'Runtime Error: Cannot pass a step that is not `WaitForConfirmation` to this validator.',
    );
  }

  const { mode, choices } = step.args;

  const modeToVariableName: Required<{
    [K in WaitForConfirmationModes]: string;
  }> = {
    confirm: 'confirmed',
    choice: 'choice',
    freeform: 'freeformInput',
  };

  // Check #1: Modes must match up. Will get out of sync if the "prompt user" step mode is changed _after_ the conditional step is changed.
  if (modeToVariableName[mode] !== variableName) {
    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: `Condition must be updated: Variable "${stepPosition}. Prompt User" is invalid. Update to reflect that the prompt is now of type "${mode}."`,
      fieldId: 'condition',
    });
  }

  // Check #2: If 'choice', ensure that we're not checking against a stale option.
  if (
    mode === 'choice' &&
    !choices.includes((condition.value || '').toString())
  ) {
    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: `Condition must be updated: Value for variable "${stepPosition}. Prompt User" is invalid. Choice must be updated.`,
      fieldId: 'condition',
    });
  }
};

/**
 * Step validator for conditional input steps.
 *
 * Example of cases where validation needed: Conditional based on step is created and valid. Then the step is changed or deleted. Now the conditional is invalid.
 *
 * TODO Add validation for case where order of step is moved beneath this step?
 */
export const validateConditionalSteps = (
  conditions: ConditionalArgument.ConditionalBuilderState[][],
  routine: Routine.ConvertedResponse,
) => {
  for (let i = 0; i < conditions.length; i += 1) {
    for (let j = 0; j < conditions[i].length; j += 1) {
      const condition = conditions[i][j];

      if (condition.variable?.kind !== 'step') continue;

      const step = routine.stepConfigurations[condition.variable.stepID];

      if (!step) {
        // If step was deleted, condition cannot rely on this.
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Condition must be updated: Makes use of invalid step.',
          fieldId: 'condition',
        });
      }

      if (step.stepKind !== condition.variable.stepKind) {
        // This is a bug rather than a validation issue.
        throw new Error(
          `Conditional step validation failed: step's kind is different from condition's stepKind. [step.stepKind=${step.stepKind} !== condition.variable.stepKind (${condition.variable.stepKind})].`,
        );
      }

      switch (condition.variable.stepKind) {
        case 'WaitForConfirmation':
          validateConditionalWaitForConfirmationStep(condition, step);
          break;

        default:
          break;
      }
    }
  }
};
